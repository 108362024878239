import { useGetCurrentUserQuery } from "../features/apis/authApi";
const useCurrentUser = () => {
	const { data, currentData, isLoading, isFetching, isError } =
		useGetCurrentUserQuery();
	let user;
	if (data && !isLoading && !isFetching) {
		user = data?.User;
		// localStorage.setItem("currentUser", JSON.stringify(data?.User));
		return user;
	} else if (isError && !data && !currentData) {
		return undefined;
	}
	
	return user;
};
export default useCurrentUser;
