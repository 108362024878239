import { useState, useEffect } from 'react';
import { useGetChatBotMutation } from '../../features/apis/attendanceApi';
import useCurrentUser from '../../customHook/useCurrentUser';
import DefualtIcon from '../../assets/images/profile.png';
import AiICon from '../../assets/images/chatLogo.svg';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  TypingIndicator,
  Avatar,
} from '@chatscope/chat-ui-kit-react';
import { Box, Button } from '@chakra-ui/react';
import { IoChatbubbleEllipses, IoClose } from 'react-icons/io5';
import moment from 'moment';

const ChatBot: React.FC = () => {
  const user = useCurrentUser();

  const [messages, setMessages] = useState<any[]>([
    {
      message: `Hello ${user?.name}! How can I assist you today?`,
      sender: 'AI',
      sentTime: moment().toString(),
      direction: 'incoming',
    },
  ]);

  const [userInput, setUserInput] = useState<string>('');
  const [sendMessageToAPI, { data, isLoading }] = useGetChatBotMutation();
  const [isOpen, setIsOpen] = useState(false);

  const suggestedMessages = [
    'Available Leaves',
    'How do I request a leave?',
    'Leaves Policy Summary',
    'Requeast Slip',
  ];

  const handleSendMessage = (message: string) => {
    if (!message.trim()) return;

    const newMessage = {
      message,
      sender: user?.name || 'User',
      sentTime: moment().toString(),
      direction: 'outgoing',
    };

    setMessages((prev) => [...prev, newMessage]);
    setUserInput('');

    sendMessageToAPI({
      question: message,
      user: user?.name || 'User',
      userId: user?._id,
      lastMessage:
        messages.length > 0 ? messages[messages.length - 1].message : '',
    }).unwrap();
  };

  const handleSuggestedMessageClick = (message: string) => {
    handleSendMessage(message);
  };

  useEffect(() => {
    if (user?.name) {
      setMessages((prev) => [
        {
          ...prev[0],
          message: `Hello ${user?.name}! How can I assist you today?`,
        },
        ...prev.slice(1),
      ]);
    }
  }, [user]);

  useEffect(() => {
    if (data?.response) {
      const aiMessage = {
        message: data.response,
        sender: 'AI',
        sentTime: moment().toString(),
        direction: 'incoming',
      };
      setMessages((prev) => [...prev, aiMessage]);
    }
  }, [data]);

  return (
    <>
      {/* Toggle Chat Button */}
      <Button
        onClick={() => setIsOpen(!isOpen)}
        position="fixed"
        bottom="20px"
        right="20px"
        zIndex={1000}
        borderRadius="full"
        width="60px"
        height="60px"
        color="white"
        display="flex"
        alignItems="center"
        justifyContent="center"
        boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
      >
        {isOpen ? (
          <IoClose size={30} color="#6e3ef5" />
        ) : (
          <IoChatbubbleEllipses size={30} color="#6e3ef5" />
        )}
      </Button>

      {isOpen && (
        <Box
          position="fixed"
          bottom="90px"
          right="20px"
          zIndex={1000}
          width="350px"
          height="550px"
          backgroundColor="white"
          borderRadius="8px"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
        >
          <MainContainer style={{ borderRadius: '8px' }}>
            <ChatContainer>
              <ConversationHeader style={{ backgroundColor: '#ECE8FF' }}>
                <Avatar name="AI Assistant" size="md" src={AiICon} />
                <ConversationHeader.Content userName="Tara" />
              </ConversationHeader>

              <MessageList
                typingIndicator={
                  isLoading ? (
                    <TypingIndicator content="Tara is typing..." />
                  ) : null
                }
              >
                {messages.map((msg, index) => (
                  <Message
                    key={index}
                    model={msg}
                    style={{
                      padding: '8px',
                      borderRadius: '10px',
                      maxWidth: '100%',
                      alignSelf:
                        msg.direction === 'incoming'
                          ? 'flex-start'
                          : 'flex-end',
                    }}
                  >
                    <Avatar
                      name={msg.sender}
                      size="sm"
                      src={String(
                        msg.direction === 'incoming'
                          ? AiICon
                          : user?.avatar || DefualtIcon
                      )}
                    />
                  </Message>
                ))}
                <Box
                  position="relative"
                  display="flex"
                  flexWrap="wrap"
                  flexDirection="column"
                  gap="8px"
                  p="8px"
                >
                  {suggestedMessages.map((msg, index) => (
                    <Button
                      key={index}
                      size="sm"
                      variant="outline"
                      colorScheme="black"
                      color="black"
                      fontSize="smaller"
                      padding={2}
                      border="1px solid #CFBEFF"
                      onClick={() => handleSuggestedMessageClick(msg)}
                    >
                      {msg}
                    </Button>
                  ))}
                </Box>
              </MessageList>

              <MessageInput
                value={userInput}
                onChange={(text) => setUserInput(text)}
                onSend={() => handleSendMessage(userInput)}
                placeholder={
                  isLoading ? 'Tara is thinking...' : 'Ask HR Assistant...'
                }
                attachButton={false}
                disabled={isLoading}
              />
            </ChatContainer>
          </MainContainer>
        </Box>
      )}
    </>
  );
};

export default ChatBot;
